<template>
  <div class="container">
    <div class="handle-box">
      <div style="float: left;">
        <el-button type="success" icon="el-icon-plus" @click="onAdd">
          添加
        </el-button>
        <el-button type="danger" icon="el-icon-delete" @click="delAll">
          批量删除
        </el-button>
        <el-button icon="el-icon-refresh" class="mr10" @click="onRefresh">
          刷新
        </el-button>
      </div>
      <div style="float: right;">
        <el-form
          ref="queryForm"
          :model="query"
          :inline="true"
          label-width="120px"
        >
          <el-form-item prop="name">
            <el-select
              v-model="query.name"
              clearable
              placeholder="请选择字典类别"
            >
              <el-option
                v-for="(item, index) in dictOptions"
                :key="index"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="search">
              搜索
            </el-button>
            <el-button icon="el-icon-reset" @click="resetForm('queryForm')">
              重置
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!--表格数据-->
    <div style="clear: both;"></div>
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="正在加载 ..."
      border
      fit
      highlight-current-row
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column label="类别名称 (请勿随意修改)" prop="name" />
      <el-table-column label="描述" prop="description" />
      <el-table-column label="选项名称" prop="text" />
      <el-table-column label="选项值" prop="value" />
      <el-table-column label="是否显示" prop="isview">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.isview"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="1"
            :inactive-value="0"
            @change="changeSwitch($event, scope.row, scope.$index)"
          ></el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="300" align="center">
        <template slot-scope="scope">
          <el-button type="primary" size="small" @click="onEdit(scope.row)">
            编辑
          </el-button>
          <el-button type="danger" size="small" @click="onDel(scope.row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        :current-page="pageInfo.startPage"
        :page-sizes="[10, 20, 30, 50, 100, 200]"
        :page-size="pageInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageInfo.total"
        @size-change="onSizeChange"
        @current-change="onPageChange"
      />
    </div>

    <!-- 编辑弹出框 -->
    <el-dialog title="编辑信息" width="40%" :visible.sync="selectDlgShow">
      <div
        v-loading="dlgLoading"
        class="dialog-body"
        element-loading-text="正在保存..."
      >
        <el-form ref="form" :model="form" label-width="100px">
          <el-form-item label="类别名称" prop="name" required>
            <el-input v-model="form.name" type="text" clearable />
          </el-form-item>
          <el-form-item label="描述" prop="description">
            <el-input v-model="form.description" type="text" clearable />
          </el-form-item>
          <el-form-item label="选项名称" prop="text" required>
            <el-input v-model="form.text" type="text" clearable />
          </el-form-item>
          <el-form-item label="选项值" prop="value" required>
            <el-input v-model="form.value" type="text" clearable />
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="selectDlgShow = false">取 消</el-button>
        <el-button type="info" plain size="small" @click="resetForm('form')">
          重 置
        </el-button>
        <el-button size="small" type="primary" @click="submitForm('form')">
          保存
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  saveDict,
  getDictList,
  delDict,
  getGroupNames,
  updateDictName
} from '@/api/admin/dict';
import { Message, MessageBox } from 'element-ui';
import { constants } from 'fs';

export default {
  name: 'Dict',
  data() {
    return {
      pageInfo: {
        startPage: 1,
        pageSize: 20,
        total: 0,
        name: ''
      },
      query: {
        name: ''
      },
      list: [],
      dictOptions: [],
      form: {
        id: '',
        name: '',
        description: '',
        text: '',
        value: '',
        isview: ''
      },
      listLoading: true,
      dlgLoading: false,
      selectDlgShow: false,
      multipleSelection: []
    };
  },
  created() {
    this.fetchData();
    this.loadDictOptions();
  },
  methods: {
    onPageChange(page) {
      this.pageInfo.startPage = page;
      this.fetchData();
    },
    onSizeChange(size) {
      this.pageInfo.pageSize = size;
      this.fetchData();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    loadDictOptions() {
      getGroupNames().then(resp => {
        this.dictOptions = resp.data;
      });
    },
    search() {
      this.pageInfo.name = this.query.name;
      this.fetchData();
    },
    onRefresh() {
      this.fetchData();
      this.loadDictOptions();
    },
    onAdd() {
      this.form = {
        id: '',
        name: '',
        description: '',
        text: '',
        value: '',
        isview: ''
      };
      this.selectDlgShow = true;
    },
    onEdit(row) {
      this.form = {
        id: row.id,
        name: row.name,
        description: row.description,
        text: row.text,
        value: row.value,
        isview: row.isview
      };
      this.selectDlgShow = true;
    },
    delAll() {
      this.$confirm('您确定要删除所选择的数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        var ids = [];
        this.multipleSelection.forEach(item => {
          ids.push(item.id);
        });
        delDict({ ids: ids }).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.onRefresh();
        });
      });
    },
    onDel(row) {
      this.$confirm('您确定要删除选择的数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delDict({ id: row.id }).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.onRefresh();
        });
      });
    },
    fetchData() {
      this.listLoading = true;
      getDictList(this.pageInfo).then(response => {
        console.log(response);
        this.list = response.data.records;
        this.pageInfo.total = response.data.total;
        this.pageInfo.startPage = response.data.current;
        this.pageInfo.pageSize = response.data.size;
        this.listLoading = false;
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.save();
        } else {
          return false;
        }
      });
    },
    save() {
      this.dlgLoading = true;
      saveDict(this.form).then(resp => {
        if (resp.code == 200) {
          this.$message({
            message: resp.msg,
            type: 'success'
          });
          this.selectDlgShow = false;
          this.dlgLoading = false;
          this.onRefresh();
        } else {
          this.$message.error(resp.msg);
        }
      });
    },
    changeSwitch(data, b, index) {
      //开关切换
      saveDict(b).then(resp => {
        if (resp.code == 200) {
          this.$message({
            message: '修改成功',
            type: 'success'
          });
        } else {
          this.$message.error(resp.msg);
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}
.del-dialog-cnt {
  font-size: 16px;
  text-align: center;
}
.table {
  width: 100%;
  font-size: 14px;
}
</style>
